import { AppBar, Box, Container, Stack } from '@mui/material';
import Link from 'next/link';
import { useMemo } from 'react';

import useScrollDirection from 'hooks/useScrollDirection';

import LanguageMenu from 'components/@common/SelectDropdown';
import { LogoBelgaColored } from 'components/@icons';
import { baseTheme } from 'theme/base';

const Navigation = () => {
  const scrollDirection = useScrollDirection();

  const navTransform = useMemo(() => {
    if (['inherit', 'up'].includes(scrollDirection)) return '0px';
    if (scrollDirection === 'down') return '-100%';
  }, [scrollDirection]);

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral01[700],
          borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
          transition: `transform .3s ease-in-out`,
          transform: `translateY(${navTransform})`,
        })}
      >
        <Container
          component="nav"
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              height: { xs: 56, md: 64 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link href="/" aria-label="Go home">
              <LogoBelgaColored style={{ width: '132px', height: '32px' }} />
            </Link>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              display="flex"
              justifyContent="flex-end"
              sx={{
                display: 'flex',
              }}
            >
              <LanguageMenu variant="text" />
            </Stack>
          </Box>
        </Container>
      </AppBar>
    </>
  );
};

export default Navigation;
