'use client';

import { Box, Typography } from '@mui/material';
import TabProvider from 'components/@custom/TabProvider/TabProvider';
import { ETabs } from 'constants/constants';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryStates } from 'nuqs';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';
import LatestPressReleases from '../LatestPressReleases/LatestPressReleases';
import NewsRoom from '../NewsRoom/NewsRoom';

interface Props {
  defaultTabActive?: string;
  offset?: string;
  count?: string;
  topicIds?: string[];
}

const TabsSection = ({ defaultTabActive, offset, count, topicIds }: Props) => {
  const tabActiveFromUrl = defaultTabActive || ETabs.LATEST_PRESS_RELEASES;

  const [searchParameters, setSearchParameters] = useQueryStates(
    {
      'tab-active': parseAsString.withDefault(tabActiveFromUrl),
      offset: parseAsInteger,
      count: parseAsInteger,
      topicIds: parseAsArrayOf(parseAsString),
      'search-newsrooms': parseAsString,
      'search-latest-press-releases': parseAsString,
    },
    {
      history: 'push',
      shallow: false,
    },
  );

  const items = [
    {
      key: ETabs.LATEST_PRESS_RELEASES,
      label: (
        <Typography
          sx={{
            fontSize: {
              md: '31.5px',
              sm: '24px',
            },
            lineHeight: '32px',
            fontWeight: 800,
            minWidth: 'fit-content',
            color:
              searchParameters?.['tab-active'] == ETabs.LATEST_PRESS_RELEASES
                ? theme.palette.global01[400]
                : theme.palette.neutral01[200],
          }}
        >
          <FormattedMessage id={'home.tabs.latest.press.releases'} />
        </Typography>
      ),
      children: (
        <Box sx={{ pt: theme.spacing(5) }}>
          <LatestPressReleases
            defaultTabActive={searchParameters?.['tab-active']}
            offsetFromUrl={offset}
            countFromUrl={count}
            topicIdsFromUrl={topicIds}
          />
        </Box>
      ),
    },
    {
      key: ETabs.NEWSROOMS,
      label: (
        <Typography
          sx={{
            fontSize: {
              md: '31.5px',
              sm: '24px',
            },
            lineHeight: '32px',
            fontWeight: 800,
            minWidth: 'fit-content',
            color:
              searchParameters?.['tab-active'] == ETabs.NEWSROOMS
                ? theme.palette.global01[400]
                : theme.palette.neutral01[200],
          }}
        >
          <FormattedMessage id={'home.tabs.latest.press.newsrooms'} />
        </Typography>
      ),
      children: (
        <Box sx={{ pt: theme.spacing(5) }}>
          <NewsRoom
            defaultTabActive={searchParameters?.['tab-active']}
            offsetFromUrl={offset}
            countFromUrl={count}
            topicIdsFromUrl={topicIds}
          />
        </Box>
      ),
    },
  ];

  const handleTabChange = async (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    tab: string,
  ) => {
    event.preventDefault();

    if (tab !== searchParameters?.['tab-active']) {
      await setSearchParameters({
        'tab-active': tab,
        offset: 0,
        count: tab == ETabs.LATEST_PRESS_RELEASES ? 15 : 12,
        topicIds: null,
        'search-newsrooms': null,
        'search-latest-press-releases': null,
      });
    }
  };

  return (
    <Box sx={{ marginBottom: theme.spacing(15) }}>
      <TabProvider
        tabActive={searchParameters?.['tab-active']}
        tabs={items}
        onClickLabel={handleTabChange}
      />
    </Box>
  );
};

export default TabsSection;
