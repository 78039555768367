import { Box, Typography, styled } from '@mui/material';

export const TypographySelected = styled(Typography)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  color: theme.palette.neutral01[300],
}));

export const ButtonClearSection = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  color: theme.palette.global01[400],
  textDecorationLine: 'underline',
  cursor: 'pointer',
}));
