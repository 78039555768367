import { Box } from '@mui/material';
import Footer from 'components/@footer';
import { Navigation } from 'components/@navigation';
import NavigationMobile from 'components/@navigation/NavigationMobile';
import useMedia from 'hooks/useMedia';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PrimaryLayout = ({ children }: Props) => {
  const { isMedium } = useMedia();

  return (
    <Box
      component="main"
      flex="1 auto"
      bgcolor="common.white"
      display="flex"
      flexDirection="column"
    >
      {/* {isMedium ? <NavigationMobile /> : <Navigation />} */}
      <Navigation />
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default PrimaryLayout;
