import { Box, styled } from '@mui/material';
import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import { IcoCheckboxDate, IcoUnCheckboxDate } from 'components/@icons';
import { theme } from 'theme';
import { DataDateTime } from '../DateTime/DateTime';

const Date = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  color: theme.palette.neutral01[300],
  userSelect: 'none',
}));

const WrapperCheckedDate = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

interface Props {
  isChecked: boolean;
  date: DataDateTime;
  onCheckBoxChange: (date: DataDateTime) => void;
}

const CheckboxDate = ({ date, isChecked, onCheckBoxChange }: Props) => {
  return (
    <FlexWrapper gap={theme.spacing(0.5)}>
      <WrapperCheckedDate onClick={() => onCheckBoxChange(date)}>
        {isChecked ? (
          <IcoCheckboxDate fill={theme.palette.global01[400]} />
        ) : (
          <IcoUnCheckboxDate fill={theme.palette.neutral01[400]} />
        )}
      </WrapperCheckedDate>
      <Date>{date.label}</Date>
    </FlexWrapper>
  );
};

export default CheckboxDate;
