import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';

const NotFoundData = () => {
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        borderRadius: '8px',
        backgroundColor: theme.palette.neutral01[600],
        minHeight: {
          md: '400px',
          sm: '200px',
          xs: '200px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontSize: {
            md: '28px',
            sm: '18px',
            xs: '16px',
          },
          fontWeight: 700,
          color: theme.palette.neutral01[300],
        }}
      >
        <FormattedMessage id="newsroom.not.found" />
      </Typography>
    </Box>
  );
};

export default NotFoundData;
