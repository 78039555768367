import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import NewsRoomCard from 'components/@cards/newsroom/NewsRoomCard';
import ButtonSecondary from 'components/@common/ButtonSecondary/ButtonSecondary';
import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import InputSearch from 'components/@custom/InputSearch/InputSearch';
import NewsRoomCardSkeleton from 'components/@custom/NewsRoomCardSkeleton/NewsRoomCardSkeleton';
import NotFoundData from 'components/@custom/NotFoundData/NotFoundData';
import Topic from 'components/@custom/Topic/Topic';
import TopicSkeleton from 'components/@custom/TopicSkeleton/TopicSkeleton';
import { IcoArrowDown, IcoInputSearch } from 'components/@icons';
import CustomPagination from 'components/@pagination/CustomPagination';
import { QUERY_KEY } from 'constants/QueryKey';
import { ETabs } from 'constants/constants';
import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { newsRoomsService } from 'services/api/newsRoomsService';
import topicService from 'services/api/topicService';
import { theme } from 'theme';
import { PaginationType } from 'types/pagination';
import { ITopics } from 'types/types';
import { setTopicDisplayLength, setTopics } from '../../../../redux/slices/topicSlice';
import { ButtonSelectCustom, Linear } from '../LatestPressReleases/LatestPressReleases.style';
import { NewsRoomContainer, NewsRoomGrid } from './NewsRoom.style';
import { FormattedMessage } from 'react-intl';

interface Props {
  defaultTabActive?: string;
  offsetFromUrl?: string;
  countFromUrl?: string;
  topicIdsFromUrl?: string[];
}

const NewsRoom = (props: Props) => {
  const { defaultTabActive, offsetFromUrl, countFromUrl, topicIdsFromUrl } = props;

  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(null);

  const topics = useSelector<RootState, ITopics[] | null>((state) => state.topicStore.topics);
  const topicDisplayLength = useSelector<RootState, number>(
    (state) => state.topicStore.topicDisplayLength,
  );

  const { locale } = useLocale();
  const { isSmall } = useMedia();
  const [pagination, setPagination] = useState<PaginationType>({
    pageIndex: 1,
    pageSize: 12,
    totalElements: 10,
    totalPages: 1,
  });

  const [tabElement, setTabElement] = useState<Element | null>(null);

  const [offset, setOffset] = useQueryState(
    'offset',
    parseAsInteger.withDefault(Number(offsetFromUrl) || 0),
  );
  const [count, setCount] = useQueryState(
    'count',
    parseAsInteger.withDefault(Number(countFromUrl) || 15),
  );
  const [search, setSearch] = useQueryState('search-newsrooms');
  const [topicIds, setTopicIds] = useQueryState(
    'topicIds',
    parseAsArrayOf(parseAsString).withDefault(
      topicIdsFromUrl && topicIdsFromUrl.length ? topicIdsFromUrl : [],
    ),
  );

  const [inputValue, setInputValue] = useState<string>(search || '');

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.NEWSROOMS, locale, offset, count, search, topicIds],
    queryFn: async () => {
      const response = await newsRoomsService.getNewsrooms({
        offset: offset,
        count: count,
        search: search || '',
        topicIds: topicIds.map((topicId) => Number(topicId)),
        language: locale.toLocaleUpperCase() || 'EN',
      });
      if (response && response._meta) {
        setPagination({
          pageIndex: offset / count + 1,
          pageSize: count,
          totalElements: response._meta?.total,
          totalPages: Math.ceil(response._meta?.total / count),
        });
      }
      return response;
    },
    enabled: defaultTabActive == ETabs.NEWSROOMS,
  });

  const { isLoading: isTopicLoading } = useQuery({
    queryKey: [QUERY_KEY.TOPICS],
    queryFn: async () => {
      const response: ITopics[] | undefined = await topicService.getTopics();
      if (response) {
        dispatch(setTopics([...response]));
      }
    },
    enabled: !topics,
    staleTime: 60000,
  });

  useEffect(() => {
    setInputValue(search || '');
  }, [search]);

  useEffect(() => {
    const tab = document.querySelector('.tab__section-class-name');
    setTimeout(() => {
      if (tab) {
        setTabElement(tab);
      }
    }, 400);
  }, []);

  const scrollToSection = () => {
    if (tabElement) {
      const offset = 80;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = tabElement.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    scrollToSection();
    setOffset(pageIndex == 1 ? 0 : (pageIndex - 1) * pageSize);
    setCount(pageSize);
  };

  const handleRowPerPageChange = (rowPerPage: number) => {
    setOffset(0);
    setCount(rowPerPage);
  };

  const handleClickTopic = (topicId: number) => {
    if (Array.isArray(topicIds) && topicIds.length > 0 && topicIds?.includes(topicId.toString())) {
      let newListTopicIdActive: string[] = topicIds.filter((id: string) => Number(id) !== topicId);
      setOffset(0);
      setTopicIds([...newListTopicIdActive]);
    } else {
      setOffset(0);
      setTopicIds([...topicIds!, topicId.toString()]);
    }
  };

  const handleShowMoreTopic = () => {
    if (topics) {
      dispatch(setTopicDisplayLength(topics.length));
    }
  };

  const handleShowFewerTopic = () => {
    if (topics) {
      dispatch(setTopicDisplayLength(12));
    }
  };

  const handleSelectAll = () => {
    if (topics && topics.length > 0) {
      let listTopicId: string[] = [];
      topics.slice(0, topicDisplayLength).forEach((topic) => {
        listTopicId.push(topic.id!.toString());
      });
      setOffset(0);
      setTopicIds([...listTopicId]);
    }
  };

  const handleSelectNone = () => {
    setOffset(0);
    setTopicIds([]);
  };

  return (
    <Box>
      <InputSearch
        inputId="search-newsrooms"
        placeholderMessageId={'input.search'}
        prefixIcon={<IcoInputSearch fill={theme.palette.neutral01[300]} />}
        onChange={(event) => {
          let value = event.target.value;
          setInputValue(value);
          if (timer.current) {
            clearTimeout(timer.current);
          }
          timer.current = setTimeout(() => {
            setOffset(0);
            setSearch(value);
          }, 500);
        }}
        value={inputValue}
      />
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              lg: 'repeat(6, 1fr)',
              md: 'repeat(4, 1fr)',
              sm: 'repeat(3, 1fr)',
              xs: 'repeat(2, 1fr)',
            },
            gap: theme.spacing(1),
          }}
        >
          {isTopicLoading
            ? Array.from({ length: 12 }).map((_, i) => <TopicSkeleton key={i} height={100} />)
            : topics
                ?.slice(0, topicDisplayLength)
                .map((topic) => (
                  <Topic
                    topic={topic}
                    key={topic.id}
                    handleClickTopic={handleClickTopic}
                    listTopicIdActive={
                      Array.isArray(topicIds) && topicIds.length > 0
                        ? topicIds?.map((topicId) => Number(topicId))
                        : []
                    }
                  />
                ))}
        </Box>
        <FlexWrapper
          gap={theme.spacing(isSmall ? 1 : 3)}
          sx={{
            justifyContent: 'space-between',
            marginTop: theme.spacing(2),
            flexDirection: isSmall ? 'column' : 'row',
          }}
        >
          {topics && topicDisplayLength >= topics?.length ? (
            <ButtonSecondary
              prefixIcon={
                <IcoArrowDown
                  fill={theme.palette.global01[400]}
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
              buttonText={'home.tabs.button.show.fewer.topics'}
              onClick={() => handleShowFewerTopic()}
            />
          ) : (
            <ButtonSecondary
              prefixIcon={<IcoArrowDown fill={theme.palette.global01[400]} />}
              buttonText={'home.tabs.button.show.more.topics'}
              onClick={() => handleShowMoreTopic()}
            />
          )}
          <Linear />
          <FlexWrapper>
            <ButtonSelectCustom
              onClick={() => handleSelectAll()}
              sx={{
                transition: '.3s ease-in-out',
                padding: '4px 8px',
                ':hover': { backgroundColor: theme.palette.global01[600] },
              }}
            >
              <FormattedMessage id="button.select.all" />
            </ButtonSelectCustom>
            <ButtonSelectCustom
              onClick={() => handleSelectNone()}
              sx={{
                transition: '.3s ease-in-out',
                padding: '4px 8px',
                ':hover': { backgroundColor: theme.palette.global01[600] },
              }}
            >
              <FormattedMessage id="button.select.none" />
            </ButtonSelectCustom>
          </FlexWrapper>
        </FlexWrapper>
        <Box sx={{ marginTop: theme.spacing(2) }}>
          {isLoading ? (
            <NewsRoomContainer>
              <NewsRoomGrid $columns={2}>
                {Array.from({ length: 12 }).map((_, index) => (
                  <NewsRoomCardSkeleton key={index} />
                ))}
              </NewsRoomGrid>
            </NewsRoomContainer>
          ) : !isLoading && data?.data?.length == 0 ? (
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <NotFoundData />
            </Box>
          ) : (
            <NewsRoomContainer>
              <NewsRoomGrid $columns={2}>
                {data?.data?.map((item, index) => (
                  <NewsRoomCard key={index} {...item} />
                ))}
              </NewsRoomGrid>
            </NewsRoomContainer>
          )}
          {!isLoading && data?.data?.length == 0 ? (
            <></>
          ) : (
            <Box sx={{ marginTop: theme.spacing(5) }}>
              <CustomPagination
                pagination={{
                  ...pagination,
                }}
                onChange={(pageIndex: number, pageSize: number) =>
                  handlePaginationChange(pageIndex, pageSize)
                }
                onRowPerPageChange={(rowPerPage: number) => handleRowPerPageChange(rowPerPage)}
                pageSizeOptions={[12, 24, 100]}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NewsRoom;
