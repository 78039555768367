import { Box } from '@mui/material';
import { MouseEvent, ReactNode, useRef } from 'react';

interface TabItem {
  key: string;
  label: ReactNode;
  children: ReactNode;
}

const TabItem = (tab: TabItem) => {
  return <Box>{tab.children}</Box>;
};

interface TabProviderProps {
  tabActive: string;
  tabs: TabItem[];
  onClickLabel: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>, key: string) => void;
}

const TabProvider = ({ tabActive, tabs, onClickLabel }: TabProviderProps) => {
  const ref1 = useRef<Element | null>(null);
  const ref2 = useRef<Element | null>(null);

  const refData = [ref1, ref2];

  return (
    <Box className="box-1 tab__section-class-name">
      <Box className="box-2">
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
            overflowX: 'scroll',
          }}
          className="tabs-label-scroll"
        >
          {tabs.map((tab, index) => (
            <Box
              ref={refData[index]}
              key={tab.key}
              onClick={(event) => {
                refData?.[index]?.current?.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'end',
                  block: 'nearest',
                });
                onClickLabel(event, tab.key);
              }}
              className={`label-tabs-section label-tabs-section-${index} ${
                tabActive == tab.key ? `label-tabs-section-${index}-active` : ''
              }`}
              sx={{
                position: 'relative',
                padding: '12px 16px',
              }}
            >
              {tab.label}
            </Box>
          ))}
        </Box>
        {tabs.map((tab) => {
          return (
            <Box key={tab.key} sx={{ display: tabActive == tab.key ? 'block' : 'none' }}>
              <TabItem {...tab} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TabProvider;
