import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { theme } from 'theme';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { ELanguage } from 'types/types';

const WrapperSwitch = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading06,
  color: theme.palette.neutral01[100],
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading06,
  color: theme.palette.neutral01[100],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const SwitchSection = () => {
  const methods = useFormContext();

  const languages: string[] = methods.watch('languages');

  return (
    <Box>
      <TypographyTitle>
        <FormattedMessage id={'subscribe.form.modal.2.receive'} />
      </TypographyTitle>
      <WrapperSwitch>
        <FormControlLabel
          control={
            <Switch
              checked={languages ? languages.includes(ELanguage.NL) : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  methods.setValue('languages', [...languages, ELanguage.NL]);
                } else {
                  methods.setValue('languages', [
                    ...languages.filter((lang) => lang !== ELanguage.NL),
                  ]);
                }
              }}
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: theme.palette.global01[600],
                },
              }}
            />
          }
          label="Nederlands"
        />
        <FormControlLabel
          control={
            <Switch
              checked={languages ? languages.includes(ELanguage.FR) : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  methods.setValue('languages', [...languages, ELanguage.FR]);
                } else {
                  methods.setValue('languages', [
                    ...languages.filter((lang) => lang !== ELanguage.FR),
                  ]);
                }
              }}
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: theme.palette.global01[600],
                },
              }}
            />
          }
          label="French"
        />
        <FormControlLabel
          control={
            <Switch
              checked={languages ? languages.includes(ELanguage.EN) : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  methods.setValue('languages', [...languages, ELanguage.EN]);
                } else {
                  methods.setValue('languages', [
                    ...languages.filter((lang) => lang !== ELanguage.EN),
                  ]);
                }
              }}
              sx={{
                '& .MuiSwitch-track': {
                  backgroundColor: theme.palette.global01[600],
                },
              }}
            />
          }
          label="English"
        />
      </WrapperSwitch>
    </Box>
  );
};

export default SwitchSection;
