import { Box, styled } from '@mui/material';

export const NewsRoomContainer = styled(Box)(({ theme }) => ({
  // paddingBottom: 120,

  [theme.breakpoints.down('md')]: {},
}));

export const NewsRoomGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns',
})<{ $columns: number }>(({ theme, $columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,
  padding: theme.spacing(5, 0),
  marginBottom: '40px',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
}));
