import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { IcoArrowDown } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';

interface Props {
  variant?: 'text' | 'default';
  color?: string;
  isFooter?: boolean;
}

const languages = ['en', 'nl', 'fr'];

const LanguageMenu = ({ variant = 'default', color, isFooter }: Props) => {
  const { locale, updateLocale } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Box
        id="basic-button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          paddingX: '8px',
          paddingY: '3.5px',
          width: 'fit-content',
          height: '32px',
          borderRadius: '4px',
          background: isFooter ? '#1C287A' : 'transparent',
          transition: '.3s ease-in-out',
          ':hover': {
            backgroundColor: !isFooter && theme.palette.global01[600],
          },
          minWidth: isFooter ? '172px' : 'unset',
          minHeight: isFooter ? '40px' : 'unset',
          justifyContent: isFooter ? 'space-between' : 'initial',
        }}
        onClick={handleClick}
      >
        <Typography
          variant={variant === 'text' ? 'caption01' : 'webParagraph02'}
          sx={{
            color: isFooter ? theme.palette.neutral01[700] : theme.palette.neutral01[300],
            ...theme.typography.webParagraph02,
            lineHeight: '17px',
          }}
        >
          {<FormattedMessage id={`language.${locale}` as I18nKey} />}
        </Typography>
        <IcoArrowDown
          fill={isFooter ? theme.palette.neutral01[700] : theme.palette.global01[400]}
        />
      </Box>

      <Menu
        id={`basic-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          display: open ? 'initial' : 'none',
        }}
      >
        {languages.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              updateLocale(option);
              setOpen(false);
              setAnchorEl(null);
            }}
            selected={option == locale}
          >
            <Typography variant="paragraph02">
              <FormattedMessage id={`language.${option}` as I18nKey} />
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageMenu;
