import { Box, Typography } from '@mui/material';
import ButtonSecondary from 'components/@common/ButtonSecondary/ButtonSecondary';
import { IcoLearnMore } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';

const DefaultSection = () => {
  const { locale } = useLocale();

  const handleGetLinkByLocale = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/share/';
      case 'nl':
        return 'https://www.belga.press/nl/share/';
      case 'fr':
        return 'https://www.belga.press/fr/share/';
      default:
        return 'https://www.belga.press/share/';
    }
  };

  return (
    <Box
      sx={{
        paddingTop: {
          md: theme.spacing(15),
          sm: theme.spacing(10),
          xs: theme.spacing(5),
        },
        paddingBottom: {
          md: theme.spacing(15),
          sm: theme.spacing(10),
          xs: theme.spacing(5),
        },
      }}
    >
      <Box>
        <Typography
          sx={(theme) => ({
            ...theme.typography.heading01,
            fontSize: '56px',
            color: theme.palette.neutral01[200],
            maxWidth: 800,
          })}
        >
          <FormattedMessage id={'home.introduce.title'} />
        </Typography>
        <Typography
          sx={(theme) => ({
            ...theme.typography.paragraph01,
            color: theme.palette.neutral01[300],
            fontWeight: 400,
            marginTop: 2,
            fontSize: {
              md: '18px',
              sm: '14px',
              xs: '14px',
            },
            lineHeight: {
              md: '28px',
              sm: '20px',
              xs: '20px',
            },
          })}
        >
          <FormattedMessage id={'home.introduce.description'} />
        </Typography>
        <Link href={handleGetLinkByLocale()} target="_blank">
          <ButtonSecondary
            sx={{ marginTop: 2 }}
            sxText={{
              fontSize: {
                md: '13px',
                xs: '13px',
              },
              lineHeight: {
                md: '16px',
                xs: '16px',
              },
            }}
            suffixIcon={<IcoLearnMore />}
            buttonText={'home.introduce.button.text'}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default DefaultSection;
