import { Box, styled } from '@mui/material';

export const WrapperTopicSelectedItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

export const TopicSelectedItemCustom = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  paddingTop: 8,
  paddingBottom: 8,
  paddingRight: 4,
  paddingLeft: 8,
  ...theme.typography.webParagraph02,
  background: theme.palette.neutral01[600],
  color: theme.palette.neutral01[300],
  fontWeight: 600,
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
}));
