import { Box, Button, PaginationItem, Select, styled } from '@mui/material';

export const Root = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  borderRadius: '8px',
  padding: '12px',
  height: '40px',
  width: '40px',
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.neutral01[300],
  ':hover': {
    backgroundColor: theme.palette.global01[700],
  },
  '&.MuiPaginationItem-firstLast': {
    backgroundColor: theme.palette.global01[700],
  },
  '&.MuiPaginationItem-previousNext': {
    backgroundColor: theme.palette.global01[700],
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.global01[700],
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
}));

export const RowsPerPage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  p: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.neutral01[300],
  },
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
  fontSize: '14px !important',
  lineHeight: '24px !important',
  fieldset: {
    border: 'none',
  },
  transition: '.3s ease-in-out',
  marginLeft: '4px',
  ':hover': {
    backgroundColor: theme.palette.global01[600],
  },
}));

export const ElementOfElements = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  p: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.neutral01[300],
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '10px',
  },
}));
