import { IcoCheckedTopic, IcoPlusTopic } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import Image from 'next/image';
import { theme } from 'theme';
import { ITopics } from 'types/types';
import ImageNotFound from '../../../../../../public/images/400x400.png';
import { TopicItemCustom, TopicItemTitle } from './TopicItem.style';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

interface Props {
  topic: ITopics;
  viewOnly?: boolean;
  active?: boolean;
  onTopicClick?: (topic?: ITopics) => void;
}

const TopicItem = ({ topic, active = false, viewOnly, onTopicClick }: Props) => {
  const { locale } = useLocale();

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [quality, setQuality] = useState<number>(10);

  return viewOnly ? (
    <TopicItemCustom $active={active} sx={{ cursor: 'default' }}>
      {!isImageLoaded && <Skeleton variant="rounded" animation="wave" width={32} height={32} />}
      <Image
        src={topic.thumbnailUrl && !isImageError ? topic.thumbnailUrl : ImageNotFound}
        quality={quality}
        width={100}
        height={100}
        alt="Topic Image"
        style={{
          maxWidth: 32,
          maxHeight: 32,
          objectFit: 'cover',
          opacity: isImageLoaded ? 1 : 0,
          width: isImageLoaded ? '32px' : '0px',
        }}
        onLoad={() => {
          setIsImageLoaded(true);
          setTimeout(() => {
            setQuality(90);
          }, 2000);
        }}
        onError={() => setIsImageError(true)}
      />
      <TopicItemTitle $active={active}>{topic.content?.[locale]?.title}</TopicItemTitle>
    </TopicItemCustom>
  ) : (
    <TopicItemCustom $active={active} onClick={() => onTopicClick?.(topic)}>
      {!isImageLoaded && <Skeleton variant="rounded" animation="wave" width={32} height={32} />}
      <Image
        src={topic.thumbnailUrl && !isImageError ? topic.thumbnailUrl : ImageNotFound}
        quality={quality}
        width={100}
        height={100}
        alt="Topic Image"
        style={{
          maxWidth: 32,
          maxHeight: 32,
          objectFit: 'cover',
          width: isImageLoaded ? '32px' : '0px',
        }}
        onLoad={() => {
          setIsImageLoaded(true);
          setTimeout(() => {
            setQuality(90);
          }, 2000);
        }}
        onError={() => setIsImageError(true)}
      />
      <TopicItemTitle $active={active}>{topic.content?.[locale]?.title}</TopicItemTitle>
      {active ? (
        <IcoCheckedTopic fill={theme.palette.neutral01[700]} />
      ) : (
        <IcoPlusTopic fill={theme.palette.global01[400]} />
      )}
    </TopicItemCustom>
  );
};

export default TopicItem;
