import { Typography } from '@mui/material';
import LanguageMenu from 'components/@common/SelectDropdown';
import { IcoFacebook, IcoLinkedIn, IcoTwitter, IcoYoutube } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import {
  About,
  Address,
  Copyright,
  FooterContainer,
  FooterLink,
  FooterLinksContainer,
  FooterWrapper,
  LogoBelga,
  SocialMediaIcons,
} from '.';
import SubscribeForUpdateSection from 'components/@pages/home/SubscribeForUpdateSection/SubscribeForUpdateSection';
import { theme } from 'theme';

const Footer = () => {
  const { locale } = useLocale();

  const handleGetAboutBelgaLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/about/';
      case 'nl':
        return 'https://www.belga.press/nl/about/';
      case 'fr':
        return 'https://www.belga.press/fr/about/';
      default:
        return 'https://www.belga.press/about/';
    }
  };

  const handleGetCustomerSupportLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/customer-support/';
      case 'nl':
        return 'https://www.belga.press/nl/customer-support/';
      case 'fr':
        return 'https://www.belga.press/fr/customer-support/';
      default:
        return 'https://www.belga.press/customer-support/';
    }
  };

  const handleGetWebinarsLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://knowledgebase.belga.be/kb/webinars/';
      case 'nl':
        return 'https://knowledgebase.belga.be/nl/kb/webinars/';
      case 'fr':
        return 'https://knowledgebase.belga.be/fr/kb/webinars/';
      default:
        return 'https://knowledgebase.belga.be/kb/webinars/';
    }
  };

  const handleGetPrivacyPolicyLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/privacy/';
      case 'nl':
        return 'https://www.belga.press/nl/privacy/';
      case 'fr':
        return 'https://www.belga.press/fr/privacy/';
      default:
        return 'https://www.belga.press/privacy/';
    }
  };

  const handleGetTermsAndConditionsLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/terms/';
      case 'nl':
        return 'https://www.belga.press/nl/terms/';
      case 'fr':
        return 'https://www.belga.press/fr/terms/';
      default:
        return 'https://www.belga.press/terms/';
    }
  };

  return (
    <>
      <SubscribeForUpdateSection />

      <FooterWrapper>
        <FooterContainer>
          <About>
            <Link href="/">
              <LogoBelga />
            </Link>
            <LanguageMenu isFooter />
            <SocialMediaIcons>
              <Link target="_blank" href="https://twitter.com/BelgaNewsAgency">
                <IcoTwitter />
              </Link>
              <Link target="_blank" href="https://www.facebook.com/belganewsagency">
                <IcoFacebook style={{ width: '15px' }} fill={theme.palette.neutral01[700]} />
              </Link>
              <Link target="_blank" href="https://www.linkedin.com/company/belga-news-agency/">
                <IcoLinkedIn />
              </Link>
              <Link target="_blank" href="https://www.youtube.com/user/BelgaNewsAgency">
                <IcoYoutube />
              </Link>
            </SocialMediaIcons>
            <Address>
              <FormattedMessage id="footer.address.line_one" />
              <br />
              <FormattedMessage id="footer.address.line_two" />
              <br />
              <FormattedMessage id="footer.address.line_three" />
            </Address>
          </About>

          <FooterLinksContainer>
            <Typography sx={(theme) => ({ ml: theme.spacing(1) })} variant="footerHeading05">
              <FormattedMessage id="footer.company.title" />
            </Typography>
            <FooterLink href={handleGetAboutBelgaLink()} target="_blank">
              <Typography variant="paragraph02">
                <FormattedMessage id="common.about_belga" />
              </Typography>
            </FooterLink>
          </FooterLinksContainer>

          <FooterLinksContainer>
            <Typography sx={(theme) => ({ ml: theme.spacing(1) })} variant="footerHeading05">
              <FormattedMessage id="footer.support.title" />
            </Typography>
            <FooterLink href={handleGetCustomerSupportLink()} target="_blank">
              <Typography variant="paragraph02">
                <FormattedMessage id="common.customer_support" />
              </Typography>
            </FooterLink>
            <FooterLink href={handleGetWebinarsLink()} target="_blank">
              <Typography variant="paragraph02">
                <FormattedMessage id="common.webinars" />
              </Typography>
            </FooterLink>
          </FooterLinksContainer>

          <Copyright>
            <Link
              style={{
                pointerEvents: 'none',
                cursor: 'default',
              }}
              href=""
            >
              <Typography variant="caption01">
                <FormattedMessage id="footer.copyright" />
              </Typography>
            </Link>
            <Link
              href={handleGetPrivacyPolicyLink()}
              target="_blank"
              style={{ transition: '.3s ease-in-out' }}
            >
              <Typography variant="caption01">
                <FormattedMessage id="footer.privacy_policy" />
              </Typography>
            </Link>
            <Link
              href={handleGetTermsAndConditionsLink()}
              target="_blank"
              style={{ transition: '.3s ease-in-out' }}
            >
              <Typography variant="caption01">
                <FormattedMessage id="footer.terms" />
              </Typography>
            </Link>
          </Copyright>
        </FooterContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;
