import { Box, Skeleton } from '@mui/material';
import FlexWrapper from '../FlexWrapper/FlexWrapper';

const NewsRoomCardSkeleton = () => {
  return (
    <FlexWrapper gap={2}>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{ minWidth: 64, minHeight: 64 }}
        width={64}
        height={64}
      />
      <Box sx={{ width: '100%' }}>
        <Skeleton width={'100%'} />
        <Skeleton width={'100%'} />
      </Box>
      <Skeleton variant="rounded" sx={{ minWidth: 40, minHeight: 40 }} width={40} height={40} />
    </FlexWrapper>
  );
};

export default NewsRoomCardSkeleton;
