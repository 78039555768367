import { Box, Skeleton } from '@mui/material';
import React from 'react';

interface Props {
  height?: number | string;
}

const TopicSkeleton = ({ height }: Props) => {
  return (
    <Box>
      <Skeleton animation="wave" height={height || 108} variant="rounded" />
    </Box>
  );
};

export default TopicSkeleton;
