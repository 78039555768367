import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import { IcoArrowDownTimePicker } from 'components/@icons';
import { useRef, useState, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';
import CheckboxDate from '../CheckboxDate/CheckboxDate';
import { TimePicker, TypographyTitle, WrapperDateTime, WrapperDay } from './DateTime.style';
import { EDays, TDays } from 'types/types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useLocale } from 'hooks/useLocale';
import { enGB, nl, fr } from 'date-fns/locale';
import formatDate, { DATE_FORMAT_HH_MM } from 'utils/formatDate';

registerLocale('enGB', enGB);
registerLocale('nl', nl);
registerLocale('fr', fr);

export interface DataDateTime {
  label: string;
  value: TDays;
}

export interface DataTime {
  label: string;
  value: string;
}

const dataDateTime: DataDateTime[] = [
  {
    label: 'M',
    value: EDays.MONDAY,
  },
  {
    label: 'T',
    value: EDays.TUESDAY,
  },
  {
    label: 'W',
    value: EDays.WEDNESDAY,
  },
  {
    label: 'T',
    value: EDays.THURSDAY,
  },
  {
    label: 'F',
    value: EDays.FRIDAY,
  },
  {
    label: 'S',
    value: EDays.SATURDAY,
  },
  {
    label: 'S',
    value: EDays.SUNDAY,
  },
];

interface Props {}

const DateTime = (props: Props) => {
  const { locale } = useLocale();
  const methods = useFormContext();
  const selectTimeRef = useRef<any>(null);

  const selectedTime = methods.watch('time');

  const [isOpenSelectTime, setIsOpenSelectTime] = useState<boolean>(false);
  useOutsideAlerter(selectTimeRef, () => setIsOpenSelectTime(false));

  const currentDays: string[] = methods.watch('days');

  const handleCheckBoxChange = (date: DataDateTime) => {
    if (currentDays.length <= 0) {
      methods.setValue('days', [date.value]);
    } else {
      const isDayExists = currentDays.includes(date.value);
      if (isDayExists) {
        methods.setValue(
          'days',
          currentDays.filter((day) => day !== date.value),
        );
      } else {
        methods.setValue('days', [...currentDays, date.value]);
      }
    }
  };

  const CustomInput = forwardRef((_, ref) => {
    const handleViewDateSelected = () => {
      if (selectedTime) {
        return `${formatDate.format(selectedTime, DATE_FORMAT_HH_MM)}`;
      }
      return <FormattedMessage id="time" />;
    };

    return (
      <FlexWrapper ref={ref} onClick={() => setIsOpenSelectTime(!isOpenSelectTime)}>
        <TimePicker sx={{ display: 'flex', margin: 'auto' }}>{handleViewDateSelected()}</TimePicker>
        <IcoArrowDownTimePicker fill={theme.palette.global01[400]} />
      </FlexWrapper>
    );
  });

  return (
    <WrapperDateTime>
      <TypographyTitle>
        <FormattedMessage id={'subscribe.form.modal.2.every'} />
      </TypographyTitle>
      <WrapperDay>
        {dataDateTime.map((date) => (
          <CheckboxDate
            key={date.value}
            date={date}
            isChecked={currentDays.length > 0 ? currentDays.includes(date.value) : false}
            onCheckBoxChange={handleCheckBoxChange}
          />
        ))}
      </WrapperDay>
      <FlexWrapper>
        <TypographyTitle sx={{ marginLeft: theme.spacing(2) }}>at: </TypographyTitle>
        <Box ref={selectTimeRef} className="time-picker__custom">
          <FlexWrapper sx={{ cursor: 'pointer', position: 'relative' }}>
            <DatePicker
              open={isOpenSelectTime}
              selected={selectedTime}
              onChange={(date: Date) => {
                if (date) {
                  methods.setValue('time', date);
                } else {
                  methods.setValue('time', null);
                }
                setIsOpenSelectTime(false);
              }}
              locale={locale == 'en' ? 'enGB' : locale == 'nl' ? 'nl' : 'fr'}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15} // Mỗi lựa chọn cách nhau 15 phút
              timeCaption="Time"
              dateFormat="h:mm aa"
              customInput={<CustomInput />}
            />
          </FlexWrapper>
        </Box>
      </FlexWrapper>
    </WrapperDateTime>
  );
};

export default DateTime;
