import { Box, Typography } from '@mui/material';
import ButtonPrimary from 'components/@common/ButtonPrimary/ButtonPrimary';
import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import InputSearch from 'components/@custom/InputSearch/InputSearch';
import NotFoundDataOnSearch from 'components/@custom/NotFoundDataOnSearch/NotFoundDataOnSearch';
import { IcoArrowRight, IcoInputSearch } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { theme } from 'theme';
import { ITopics } from 'types/types';
import { convertDisplayNameCategory } from 'utils/convertDisplayNameCategory';
import { groupItemsByCategory } from 'utils/groupTopicByCategory';
import TopicItem from './TopicItem/TopicItem';
import { ButtonClearSection } from './TopicSelected/TopicSelected.style';
import TopicSelectedItem from './TopicSelectedItem/TopicSelectedItem';

interface Props {
  onNext?: () => void;
}

const StepOneSection = (props: Props) => {
  const { onNext } = props;
  const methods = useFormContext();
  const { locale } = useLocale();

  const topicsFromStore = useSelector<RootState, ITopics[] | null>(
    (state) => state.topicStore.topics,
  );

  const topicsSelected: ITopics[] = methods.watch('topics');
  const searchTextTopics = methods.watch('name');

  const topicsAfterGroup = useMemo(() => {
    if (topicsFromStore && topicsFromStore.length > 0) {
      return groupItemsByCategory([
        ...topicsFromStore?.filter((topic) => {
          return topic?.content?.[locale]?.title
            ?.toLowerCase()
            ?.includes(searchTextTopics.toLowerCase());
        }),
      ]);
    }
    return null;
  }, [topicsFromStore, searchTextTopics]);

  const handleTopicClick = (topic?: ITopics) => {
    if (topic) {
      if (topicsSelected.length <= 0) {
        methods.setValue('topics', [topic]);
      } else {
        const topicExists = topicsSelected.find((top) => top.id == topic.id);
        if (topicExists) {
          methods.setValue('topics', [...topicsSelected.filter((t) => t.id !== topicExists.id)]);
        } else {
          methods.setValue('topics', [...topicsSelected, topic]);
        }
      }
    }
  };

  const handleClearSelection = () => {
    methods.setValue('topics', []);
  };

  const handleRemoveTopicSelected = (topic: ITopics) => {
    methods.setValue('topics', [...topicsSelected.filter((t) => t.id !== topic.id)]);
  };

  return (
    <Box>
      <Controller
        name="name"
        control={methods.control}
        rules={{ required: false }}
        render={({ field: { onChange, value } }) => (
          <InputSearch
            placeholderMessageId={'subscribe.form.modal.1.search.topics'}
            prefixIcon={<IcoInputSearch fill={theme.palette.neutral01[300]} />}
            onChange={onChange}
            value={value}
            allowClear
            onClear={() => onChange('')}
          />
        )}
      />
      <Box sx={{ marginTop: theme.spacing(3) }}>
        {topicsAfterGroup && topicsAfterGroup.length > 0 ? (
          <Box>
            {topicsAfterGroup.map((topicAfterGroup, index) => (
              <Box key={index} sx={{ marginBottom: theme.spacing(3) }}>
                <Typography
                  sx={{
                    ...theme.typography.webHeading06,
                    color: theme.palette.neutral01[100],
                    marginBottom: theme.spacing(1.5),
                  }}
                >
                  {convertDisplayNameCategory(topicAfterGroup.category, locale)}
                </Typography>
                <FlexWrapper sx={{ flexWrap: 'wrap', gap: theme.spacing(1) }}>
                  {topicAfterGroup.topics.map((topic) => {
                    return (
                      <TopicItem
                        key={topic.id}
                        topic={topic}
                        onTopicClick={handleTopicClick}
                        active={topicsSelected.some((t) => t.id == topic.id)}
                      />
                    );
                  })}
                </FlexWrapper>
              </Box>
            ))}
          </Box>
        ) : (
          <NotFoundDataOnSearch />
        )}
      </Box>
      {topicsSelected && topicsSelected.length > 0 && (
        <Box sx={{ mt: theme.spacing(3) }}>
          <FlexWrapper gap={theme.spacing(1)}>
            <FlexWrapper gap={theme.spacing(0.5)}>
              <Typography
                sx={{ ...theme.typography.webParagraph01, color: theme.palette.neutral01[300] }}
              >
                {topicsSelected.length}
              </Typography>
              <Typography
                sx={{ ...theme.typography.webParagraph01, color: theme.palette.neutral01[300] }}
              >
                <FormattedMessage id={'subscribe.form.modal.1.selected'} />
              </Typography>
            </FlexWrapper>
            <ButtonClearSection onClick={handleClearSelection}>
              <Typography
                sx={{ ...theme.typography.webParagraph01, color: theme.palette.global01[400] }}
              >
                <FormattedMessage id={'subscribe.form.modal.1.clear'} />
              </Typography>
            </ButtonClearSection>
          </FlexWrapper>
          <Box sx={{ marginTop: theme.spacing(2) }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {topicsSelected.map((topic, index) => (
                <TopicSelectedItem
                  key={index}
                  topic={topic}
                  onRemoveTopicSelected={handleRemoveTopicSelected}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={{ marginTop: theme.spacing(3), width: 'fit-content', marginLeft: 'auto' }}>
        <ButtonPrimary
          disabled={!topicsSelected || topicsSelected.length <= 0}
          spacingBetweenIcon={0.5}
          suffixIcon={
            <IcoArrowRight
              fill={
                !topicsSelected || topicsSelected.length <= 0
                  ? theme.palette.text.disabled
                  : theme.palette.neutral01[700]
              }
            />
          }
          buttonText={'button.continue.text'}
          onClick={onNext}
        />
      </Box>
    </Box>
  );
};

export default StepOneSection;
