import { Box, Typography, styled } from '@mui/material';

export const NewsRoomCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.global01[700],
  borderRadius: 8,
  padding: 16,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.global01[600],
    '.content': {
      border: `1px solid ${theme.palette.neutral01[400]}`,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    },
  },
  transition: '.3s',

  [theme.breakpoints.down('md')]: {},
}));

export const NewsRoomCardLeftContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {},
}));

export const NewsRoomCardName = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[200],
  fontSize: 18,
  lineHeight: '22px',
  fontWeight: 'bold',
}));

export const NewsRoomCardRightContent = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  padding: 8,
  backgroundColor: theme.palette.global01[600],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '.3s',
  borderColor: theme.palette.global01[600],
}));
