import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ButtonPrimary from 'components/@common/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from 'components/@common/ButtonSecondary/ButtonSecondary';
import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import { IcoArrowLeft, IcoArrowRight } from 'components/@icons';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';
import DateTime from './DateTime/DateTime';
import { WrapperDate, WrapperRadioButton } from './StepTwoSection.style';
import SwitchSection from './Switch/Switch';
import { useFormContext } from 'react-hook-form';

interface Props {
  onBack?: () => void;
  onNext?: () => void;
}

type RadioButtonType = 'instant' | 'intervals';

const StepTwoSection = (props: Props) => {
  const { onBack, onNext } = props;

  const methods = useFormContext();
  const isInstant: boolean = methods.watch('instant');
  const currentDays: string[] = methods.watch('days');
  const currentTime: string[] = methods.watch('time');

  const [value, setValue] = useState<RadioButtonType>(isInstant ? 'instant' : 'intervals');

  const isAllowContinue: boolean = useMemo(() => {
    if (isInstant) {
      return false;
    } else {
      if (!currentDays || currentDays.length <= 0 || !currentTime || currentTime.length <= 0) {
        return true;
      } else {
        return false;
      }
    }
  }, [isInstant, currentDays, currentTime]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value as RadioButtonType;
    setValue(value);
    if (value == 'instant') {
      methods.setValue('instant', true);
    } else {
      methods.setValue('instant', false);
    }
  };

  return (
    <Box>
      <WrapperRadioButton>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="instant"
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14,
                  },
                }}
              />
            }
            label={<FormattedMessage id={'subscribe.form.modal.2.instant.updates.email'} />}
          />
          <FormControlLabel
            value="intervals"
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 14,
                  },
                }}
              />
            }
            label={<FormattedMessage id={'subscribe.form.modal.2.scheduled.summaries.email'} />}
          />
        </RadioGroup>
      </WrapperRadioButton>
      {value == 'intervals' && (
        <WrapperDate>
          <DateTime />
        </WrapperDate>
      )}
      <SwitchSection />
      <FlexWrapper
        sx={{
          marginTop: theme.spacing(3),
          justifyContent: 'space-between',
          gap: theme.spacing(3),
        }}
      >
        <ButtonSecondary
          spacingBetweenIcon={0.5}
          prefixIcon={<IcoArrowLeft fill={theme.palette.global01[400]} />}
          buttonText={'button.go.back'}
          onClick={onBack}
        />
        <ButtonPrimary
          disabled={isAllowContinue}
          spacingBetweenIcon={0.5}
          suffixIcon={
            <IcoArrowRight
              fill={isAllowContinue ? theme.palette.text.disabled : theme.palette.neutral01[700]}
            />
          }
          buttonText={'button.continue.text'}
          onClick={onNext}
        />
      </FlexWrapper>
    </Box>
  );
};

export default StepTwoSection;
