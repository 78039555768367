import { TOPIC_URL } from './apiUrl';
import axiosClient from './axiosService';

const topicService = {
  getTopics: async () => {
    return axiosClient()({
      url: TOPIC_URL,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
};

export default topicService;
