import { Container } from '@mui/material';
import PrimaryLayout from 'components/@layout/PrimaryLayout/PrimaryLayout';
import DefaultSection from 'components/@pages/home/DefaultSection';
import TabsSection from 'components/@pages/home/TabsSection';
import Head from 'next/head';
import { ReactElement } from 'react';
import { NextPageWithLayout } from './_app';
import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import { default as IntroImage } from '/public/android-chrome-512x512.png';

const Home: NextPageWithLayout = ({ query }: any) => {
  return (
    <>
      <Head>
        <title>Belga Share</title>
      </Head>
      <NextSeo
        title={'Home Page'}
        description={
          'Explore and discover the latest press releases. Belga.share is the go-to platform for journalists, news enthusiasts, and communication professionals to discover insightful press releases from companies, institutions and individuals'
        }
        openGraph={{
          title: 'Explore and discover the latest press releases',
          description:
            'Belga.share is the go-to platform for journalists, news enthusiasts, and communication professionals to discover insightful press releases from companies, institutions and individuals.',
          url: `${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}`,
          images: [
            {
              url: process.env.NEXT_PUBLIC_FRONTEND_DOMAIN + IntroImage.src,
              alt: 'Belga Share cover image',
            },
          ],
          siteName: `BelgaShare.be`,
        }}
        canonical={`${process.env.NEXT_PUBLIC_FRONTEND_DOMAIN}`}
      />
      <Container>
        <DefaultSection />
      </Container>
      <Container>
        <TabsSection
          defaultTabActive={query?.['tab-active']}
          offset={query?.['offset']}
          count={query?.['count']}
          topicIds={query?.['topicIds']}
        />
      </Container>
    </>
  );
};

const WrappedHome: NextPageWithLayout = (props) => {
  const getLayout = (page: ReactElement) => <PrimaryLayout>{page}</PrimaryLayout>;
  return getLayout(<Home {...props} />);
};

export default WrappedHome;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context;

  return {
    props: {
      query: query,
    },
  };
};
