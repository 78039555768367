import { Box } from '@mui/material';
import ButtonPrimary from 'components/@common/ButtonPrimary/ButtonPrimary';
import ModalPrimary from 'components/@custom/Modal';
import { IconRing } from 'components/@icons';
import StepFourSection from 'components/@pages/new/StepFourSection/StepFourSection';
import StepOneSection from 'components/@pages/new/StepOneSection/StepOneSection';
import StepThreeSection from 'components/@pages/new/StepThreeSection/StepThreeSection';
import StepTwoSection from 'components/@pages/new/StepTwoSection/StepTwoSection';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  ContainerCustom,
  IconRingSubscribe,
  SubscribeForUpdate,
  SubscribeForUpdateText,
} from './SubscribeForUpdateSection.style';

interface Modal {
  stepOne: boolean;
  stepTwo: boolean;
  stepThree: boolean;
  stepFour: boolean;
}

const defaultValues = {
  name: '',
  topics: [],
  days: [],
  time: null,
  languages: [],
  email: '',
  instant: true,
  isAgree: false,
};

const SubscribeForUpdateSection = () => {
  const methods = useForm<any>({
    defaultValues: defaultValues,
  });

  const [openModal, setOpenModal] = useState<Modal>({
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
  });

  const handleOpenModalStepOne = () => {
    setOpenModal({
      stepTwo: false,
      stepThree: false,
      stepFour: false,
      stepOne: true,
    });
  };

  const handleOpenModalStepTwo = () => {
    setOpenModal({
      stepThree: false,
      stepOne: false,
      stepFour: false,
      stepTwo: true,
    });
  };

  const handleOpenModalStepThree = () => {
    setOpenModal({
      stepOne: false,
      stepTwo: false,
      stepFour: false,
      stepThree: true,
    });
  };

  const handleOpenModalStepFour = () => {
    setOpenModal({
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: true,
    });
  };

  const handleCloseAllModal = () => {
    methods.reset(defaultValues);
    setOpenModal({
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
    });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <ModalPrimary
          title={<FormattedMessage id={'subscribe.form.modal.1.title'} />}
          description={<FormattedMessage id={'subscribe.form.modal.1.description'} />}
          isOpen={openModal.stepOne}
          onClose={() => handleCloseAllModal()}
        >
          <StepOneSection onNext={handleOpenModalStepTwo} />
        </ModalPrimary>

        <ModalPrimary
          title={<FormattedMessage id={'subscribe.form.modal.2.title'} />}
          description={<FormattedMessage id={'subscribe.form.modal.2.description'} />}
          isOpen={openModal.stepTwo}
          onClose={() => handleCloseAllModal()}
        >
          <StepTwoSection onBack={handleOpenModalStepOne} onNext={handleOpenModalStepThree} />
        </ModalPrimary>

        <ModalPrimary
          title={<FormattedMessage id={'subscribe.form.modal.3.title'} />}
          description={<FormattedMessage id={'subscribe.form.modal.3.description'} />}
          isOpen={openModal.stepThree}
          onClose={() => handleCloseAllModal()}
        >
          <StepThreeSection onBack={handleOpenModalStepTwo} onNext={handleOpenModalStepFour} />
        </ModalPrimary>

        <ModalPrimary isOpen={openModal.stepFour} onClose={() => handleCloseAllModal()}>
          <StepFourSection onNext={handleCloseAllModal} />
        </ModalPrimary>

        <SubscribeForUpdate>
          <ContainerCustom>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconRingSubscribe>
                <IconRing />
              </IconRingSubscribe>
              <SubscribeForUpdateText>
                <FormattedMessage id={'home.tabs.text.subscribe'} />
              </SubscribeForUpdateText>
            </Box>
            <ButtonPrimary
              sx={{ minWidth: 'fit-content' }}
              buttonText="home.tabs.button.subscribe"
              onClick={() =>
                setOpenModal((prev) => ({
                  ...prev,
                  stepOne: true,
                }))
              }
            />
          </ContainerCustom>
        </SubscribeForUpdate>
      </form>
    </FormProvider>
  );
};

export default SubscribeForUpdateSection;
