import { ITopics } from 'types/types';

export function groupItemsByCategory(items: ITopics[]): { category: string; topics: ITopics[] }[] {
  const groupedItems: { [category: string]: ITopics[] } = {};

  items.forEach((item) => {
    if (groupedItems[item.category]) {
      groupedItems[item.category].push(item);
    } else {
      groupedItems[item.category] = [item];
    }
  });

  return Object.keys(groupedItems).map((category) => ({
    category,
    topics: groupedItems[category],
  }));
}
