import { Box, styled } from '@mui/material';

export const WrapperDateTime = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.palette.neutral01[600],

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
  },
}));

export const WrapperDay = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const TypographyTitle = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph02,
  color: theme.palette.neutral01[300],
  marginRight: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 0,
    lineHeight: '24px',
  },
}));

export const TimePicker = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph02,
  color: theme.palette.neutral01[300],
  fontWeight: 400,
}));
