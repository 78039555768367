import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NotFoundDataOnSearch = () => {
  return (
    <Box sx={{}}>
      <Typography
        sx={{
          fontSize: {
            md: '18px',
            sm: '16px',
            xs: '16px',
          },
          fontWeight: 700,
          color: theme.palette.neutral01[300],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <SearchOffIcon />
        <FormattedMessage id="not.found.data.on.search" />
      </Typography>
    </Box>
  );
};

export default NotFoundDataOnSearch;
