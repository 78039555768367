import { MenuItem, Typography } from '@mui/material';
import {
  CustomPaginationItem,
  CustomSelect,
  ElementOfElements,
  Root,
  RowsPerPage,
} from './CustomTablePagination.style';
import Pagination from '@mui/material/Pagination';
import {
  IcoArrow,
  IcoChevronNext,
  IcoChevronNextLast,
  IcoChevronPrevious,
  IcoChevronPreviousFirst,
} from 'components/@icons';
import { theme } from 'theme';
import { PaginationType } from 'types/pagination';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

interface Props {
  pagination: PaginationType;
  pageSizeOptions: number[];
  onChange?: (pageIndex: number, pageSize: number) => void;
  onRowPerPageChange?: (rowPerPage: number) => void;
}

export default function CustomPagination({
  pagination,
  pageSizeOptions,
  onChange,
  onRowPerPageChange,
}: Props) {
  const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
  const endIndex = Math.min(startIndex + pagination.pageSize - 1, pagination.totalElements);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <Root>
      <RowsPerPage>
        <Typography>
          <FormattedMessage id={'pagination.row.per.page'} />
        </Typography>
        <CustomSelect
          onChange={(event) => {
            onRowPerPageChange?.(Number(event.target.value));
          }}
          value={pagination.pageSize}
          IconComponent={(props) => (
            <IcoArrow
              {...props}
              fill={theme.palette.global01[400]}
              style={{ fontSize: '28px', fontWeight: 'bold' }}
            />
          )}
        >
          {pageSizeOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              sx={{ fontSize: '14px', paddingY: '0px', display: 'flex', justifyContent: 'center' }}
            >
              {option} / <FormattedMessage id="pagination.page" />
            </MenuItem>
          ))}
        </CustomSelect>
      </RowsPerPage>
      <ElementOfElements>
        <Typography>{`${startIndex} - ${endIndex} of ${pagination.totalElements}`}</Typography>
      </ElementOfElements>
      <Pagination
        count={pagination.totalPages}
        page={pagination.pageIndex}
        renderItem={(item) => {
          return (
            <CustomPaginationItem
              components={{
                last: (props) => (
                  <IcoChevronNextLast
                    fill={theme.palette.global01[400]}
                    style={{ fontSize: 20 }}
                    className="hover-pagination"
                  />
                ),
                next: (props) => (
                  <IcoChevronNext
                    fill={theme.palette.global01[400]}
                    style={{ fontSize: 20 }}
                    className="hover-pagination"
                  />
                ),
                first: (props) => (
                  <IcoChevronPreviousFirst
                    fill={theme.palette.global01[400]}
                    style={{ fontSize: 20 }}
                    className="hover-pagination"
                  />
                ),
                previous: (props) => (
                  <IcoChevronPrevious
                    fill={theme.palette.global01[400]}
                    style={{ fontSize: 20 }}
                    className="hover-pagination"
                  />
                ),
              }}
              {...item}
            />
          );
        }}
        showFirstButton
        showLastButton
        onChange={(event, page) => {
          onChange?.(Number(page), Number(pagination.pageSize));
        }}
        size="small"
        siblingCount={0}
        boundaryCount={2}
      />
    </Root>
  );
}
