import { Box } from '@mui/material';
import ButtonPrimary from 'components/@common/ButtonPrimary/ButtonPrimary';
import { IcoDoneSuccess } from 'components/@icons';
import { theme } from 'theme';
import {
  FormSuccessButton,
  FormSuccessDescription,
  FormSuccessTitle,
  WrapperFormSuccess,
  WrapperTopicFormSuccess,
} from '../../home/SubscribeForUpdateSection/SubscribeForUpdateSection.style';
import TopicItem from '../StepOneSection/TopicItem/TopicItem';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { ITopics } from 'types/types';

const dataSelected = [
  {
    topicName: 'Algemeen nieuws',
  },
  {
    topicName: 'Politiek en overheid',
  },
  {
    topicName: 'Economie en geld',
  },
  {
    topicName: 'Klimaat, milieu en duurzaamheid',
  },
  {
    topicName: 'Wetenschap',
  },
  {
    topicName: 'Kunst en cultuur',
  },
];

interface Props {
  onNext?: () => void;
}

const StepFourSection = (props: Props) => {
  const { onNext } = props;

  const methods = useFormContext();
  const topicsSelected: ITopics[] = methods.watch('topics');

  return (
    <Box>
      <WrapperFormSuccess>
        <IcoDoneSuccess fill={theme.palette.success.main} />
        <FormSuccessTitle>
          <FormattedMessage id={'subscribe.form.modal.4.title'} />
        </FormSuccessTitle>
        <FormSuccessDescription>
          <FormattedMessage id={'subscribe.form.modal.4.description'} />
        </FormSuccessDescription>
        <WrapperTopicFormSuccess>
          {topicsSelected.map((topic, index) => (
            <TopicItem key={index} topic={topic} viewOnly />
          ))}
        </WrapperTopicFormSuccess>
      </WrapperFormSuccess>
      <FormSuccessButton>
        <ButtonPrimary buttonText={'button.understand'} onClick={onNext} />
      </FormSuccessButton>
    </Box>
  );
};

export default StepFourSection;
