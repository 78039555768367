import { Box, Container, Typography, styled } from '@mui/material';

const ContainerCustom = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const SubscribeForUpdate = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.global01[300],
  width: '100%',
  padding: theme.spacing(2.5, 0, 2.5),
  color: theme.palette.neutral01[700],
  ...theme.typography.webHeading03,
  [theme.breakpoints.down('md')]: {
    ...theme.typography.webHeading05,
  },
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.webHeading03,
  },
}));

const IconRingSubscribe = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  minWidth: 40,
  minHeight: 40,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 16,
  backgroundColor: theme.palette.global01[100],
}));

const SubscribeForUpdateText = styled(Typography)(({ theme }) => ({
  ...theme.typography.heading07,
  [theme.breakpoints.down('md')]: {
    ...theme.typography.webParagraph01,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none !important',
  },
}));

const WrapperLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: 10,
  marginBottom: theme.spacing(1),
}));

const TypographyCustom = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  textTransform: 'uppercase',
  lineHeight: '16px',
  color: theme.palette.neutral01[300],
}));

const TypographyRequireCustom = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '15px',
  color: theme.palette.error.main,
}));

const WrapperCheckbox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(3),
}));

const CheckboxBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'fit-content',
  '.MuiCheckbox-root': {
    marginLeft: 0,
  },
}));

const TypographyCheckboxTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[300],
  ...theme.typography.webParagraph01,
}));

const TypographyCheckboxTitleUnderLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[300],
  ...theme.typography.webParagraph01,
  display: 'inline',
  textDecorationLine: 'underline',
  marginLeft: theme.spacing(0.5),
  fontSize: 12,
  lineHeight: '16px',
}));

const TypographyCheckboxDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[300],
  fontSize: 11,
  lineHeight: '24px',
  // marginTop: theme.spacing(0.5),
}));

const TypographyCheckboxDescriptionUnderLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral01[300],
  fontSize: 11,
  lineHeight: '16px',
  display: 'inline',
  textDecorationLine: 'underline',
  marginLeft: theme.spacing(0.5),
}));

const WrapperFormSuccess = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

const FormSuccessTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading02,
  color: theme.palette.neutral01[100],
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const FormSuccessDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading06,
  fontWeight: 400,
  color: theme.palette.neutral01[300],
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const WrapperTopicFormSuccess = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const FormSuccessButton = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
}));

export {
  ContainerCustom,
  SubscribeForUpdate,
  SubscribeForUpdateText,
  IconRingSubscribe,
  WrapperLabel,
  TypographyCustom,
  TypographyRequireCustom,
  WrapperCheckbox,
  CheckboxBox,
  TypographyCheckboxTitle,
  TypographyCheckboxTitleUnderLine,
  TypographyCheckboxDescription,
  TypographyCheckboxDescriptionUnderLine,
  WrapperFormSuccess,
  FormSuccessTitle,
  FormSuccessDescription,
  WrapperTopicFormSuccess,
  FormSuccessButton,
};
