import { Box, Typography, styled } from '@mui/material';
import { IcoCheckboxActive, IcoUnCheck } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { theme } from 'theme';
import { ITopics } from 'types/types';
import ImageDefault from '../../../../public/images/category-not-found.jpg';
import ImageWidthSkeletonCustom from '../ImageWidthSkeletonCustom/ImageWidthSkeletonCustom';

const BoxCustom = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.spacing(1),
}));

const BoxCheckbox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: 24,
  width: 24,
  top: 8,
  left: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const TypographyCustom = styled(Typography)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  lineHeight: '19px',
  position: 'absolute',
  bottom: 8,
  left: 12,
  fontWeight: 'bold',
  color: theme.palette.neutral01[700],
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  marginRight: '6px',
}));

const OverlayPrimary = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transition: '0.2s',
  opacity: 0.4,
  background: theme.palette.neutral01[100],
}));

const OverlaySecondary = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transition: '0.2s',
  background: theme.palette.global01[400],
}));

interface TopicProps {
  topic: ITopics;
  listTopicIdActive: number[];
  handleClickTopic: (topicId: number) => void;
}

const Topic = ({ topic, listTopicIdActive, handleClickTopic }: TopicProps) => {
  const { locale } = useLocale();

  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [quality, setQuality] = useState<number>(10);

  return (
    <BoxCustom onClick={() => handleClickTopic(topic.id!)} className="topics-overlay-hover">
      {!listTopicIdActive?.includes(topic.id!) && <OverlayPrimary className="OverlayPrimary" />}
      <OverlaySecondary
        sx={{ opacity: listTopicIdActive?.includes(topic.id!) ? 0.6 : 0, zIndex: 99 }}
      />
      <BoxCheckbox sx={{ zIndex: 200 }}>
        {listTopicIdActive?.includes(topic.id!) ? (
          <IcoCheckboxActive fill={theme.palette.neutral01[700]} />
        ) : (
          <IcoUnCheck fill={theme.palette.neutral01[700]} />
        )}
      </BoxCheckbox>
      <ImageWidthSkeletonCustom
        loaded={isImageLoaded}
        heightSkeleton={'100%'}
        sxSkeleton={{ borderRadius: '12px', position: 'absolute' }}
        children={
          <Image
            src={topic?.thumbnailUrl && !isImageError ? topic?.thumbnailUrl : ImageDefault}
            alt="Cover Image"
            quality={quality}
            width={300}
            height={200}
            onLoad={() => {
              setIsImageLoaded(true);
              setTimeout(() => {
                setQuality(90);
              }, 2000);
            }}
            onError={() => setIsImageError(true)}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'cover',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          />
        }
      />

      <TypographyCustom sx={{ zIndex: 200 }}>
        {topic?.content?.[locale]?.title || ''}
      </TypographyCustom>
    </BoxCustom>
  );
};

export default Topic;
