import { Box, Skeleton } from '@mui/material';
import { IcoChevronNext } from 'components/@icons';
import { PATH_NAME } from 'constants/PathName';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { theme } from 'theme';
import { INewsrooms } from 'types/types';
import ImageNotFound from '../../../../public/images/category-not-found.jpg';
import {
  NewsRoomCardContainer,
  NewsRoomCardLeftContent,
  NewsRoomCardName,
  NewsRoomCardRightContent,
} from './NewsRoomCard.style';

const NewsRoomCard = ({ subSourceName, logoUrl, id }: INewsrooms) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isLogoImageError, setIsLogoImageError] = useState<boolean>(false);
  const [quality, setQuality] = useState<number>(10);

  return (
    <Link href={`${PATH_NAME.NEWSROOMS}/${id}`}>
      <NewsRoomCardContainer>
        <NewsRoomCardLeftContent>
          {!isImageLoaded && <Skeleton variant="rounded" animation="wave" height={64} width={64} />}

          <Box
            sx={{
              width: isImageLoaded ? '64px' : '0px',
              height: '64px',
              maxWidth: '64px',
              maxHeight: '64px',
              minWidth: '64px',
              minHeight: '64px',
            }}
          >
            <Image
              src={logoUrl && !isLogoImageError ? logoUrl : ImageNotFound}
              alt="logo"
              width={160}
              height={160}
              quality={quality}
              style={{
                borderRadius: 8,
                objectFit: logoUrl && !isLogoImageError ? 'contain' : 'cover',
                background: '#FFFFFF',
                opacity: isImageLoaded ? 100 : 0,
                pointerEvents: isImageLoaded ? 'auto' : 'none',
                width: '100%',
                height: '100%',
              }}
              onLoad={() => {
                setIsImageLoaded(true);
                setTimeout(() => {
                  setQuality(90);
                }, 2000);
              }}
              onError={() => setIsLogoImageError(true)}
            />
          </Box>

          <NewsRoomCardName sx={{ marginLeft: '16px' }}>{subSourceName}</NewsRoomCardName>
        </NewsRoomCardLeftContent>
        <NewsRoomCardRightContent className="content">
          <IcoChevronNext fill={theme.palette.global01[400]} fontSize={24} />
        </NewsRoomCardRightContent>
      </NewsRoomCardContainer>
    </Link>
  );
};

export default NewsRoomCard;
