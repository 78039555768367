import { Locale } from 'types/i18n.types';

enum ECategory {
  SOCIETY_ENVIRONMENT = 'SOCIETY_ENVIRONMENT',
  CONSUMER_HOBBY = 'CONSUMER_HOBBY',
  SCIENCE_INNOVATION_EDUCATION = 'SCIENCE_INNOVATION_EDUCATION',
  CULTURE_LIFESTYLE_MEDIA = 'CULTURE_LIFESTYLE_MEDIA',
}

type TCategory =
  | ECategory.SOCIETY_ENVIRONMENT
  | ECategory.CONSUMER_HOBBY
  | ECategory.SCIENCE_INNOVATION_EDUCATION
  | ECategory.CULTURE_LIFESTYLE_MEDIA;

export const convertDisplayNameCategory = (category: string, locale: Locale) => {
  switch (locale) {
    case 'nl':
      switch (category as TCategory) {
        case ECategory.SOCIETY_ENVIRONMENT:
          return 'Samenleving en Milieu';
        case ECategory.CONSUMER_HOBBY:
          return 'Consument en Vrije tijd';
        case ECategory.SCIENCE_INNOVATION_EDUCATION:
          return 'Wetenschap, onderwijs en innovatie';
        case ECategory.CULTURE_LIFESTYLE_MEDIA:
          return 'Cultuur, levensstijl en media';
      }
    case 'fr':
      switch (category as TCategory) {
        case ECategory.SOCIETY_ENVIRONMENT:
          return 'Société et Environnement';
        case ECategory.CONSUMER_HOBBY:
          return 'Consommation et loisirs';
        case ECategory.SCIENCE_INNOVATION_EDUCATION:
          return 'Science, éducation et innovation';
        case ECategory.CULTURE_LIFESTYLE_MEDIA:
          return 'Culture, style de vie et médias';
      }
    default:
      switch (category as TCategory) {
        case ECategory.SOCIETY_ENVIRONMENT:
          return 'Society and Environment';
        case ECategory.CONSUMER_HOBBY:
          return 'Consumer and Leisure';
        case ECategory.SCIENCE_INNOVATION_EDUCATION:
          return 'Science, education and innovation';
        case ECategory.CULTURE_LIFESTYLE_MEDIA:
          return 'Culture, Lifestyle and Media';
      }
  }
};
