import { Box, Typography, styled } from '@mui/material';

export const TypographyDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  color: theme.palette.neutral01[300],
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  lineHeight: '21px',
}));

export const WrapperRadioButton = styled(Box)(({ theme }) => ({}));

export const WrapperDate = styled(Box)(({ theme }) => ({}));
