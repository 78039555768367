import { Box, Checkbox, Typography } from '@mui/material';
import ButtonSecondary from 'components/@common/ButtonSecondary/ButtonSecondary';
import FlexWrapper from 'components/@custom/FlexWrapper/FlexWrapper';
import InputSecondary from 'components/@custom/InputSecondary';
import LoadingButtonCustom from 'components/@custom/LoadingButton/LoadingButton';
import { IcoArrowLeft, IcoArrowRight } from 'components/@icons';
import { useLocale } from 'hooks/useLocale';
import Link from 'next/link';
import { useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { subscribeForUpdateService } from 'services/api/subcribeForUpdateService';
import { theme } from 'theme';
import { IDataSubscribeToTopics, ITopics } from 'types/types';
import formatDate, { DATE_FORMAT_HH_MM } from 'utils/formatDate';
import {
  CheckboxBox,
  TypographyCheckboxDescription,
  TypographyCheckboxTitle,
  TypographyCheckboxTitleUnderLine,
  TypographyCustom,
  TypographyRequireCustom,
  WrapperCheckbox,
  WrapperLabel,
} from '../../home/SubscribeForUpdateSection/SubscribeForUpdateSection.style';
import AlertCustom from 'components/@custom/AlertCustom/AlertCustom';
import useMedia from 'hooks/useMedia';

interface Props {
  onBack?: () => void;
  onNext?: () => void;
}

const StepThreeSection = (props: Props) => {
  const { onBack, onNext } = props;

  const { locale } = useLocale();
  const { isSmall } = useMedia();
  const timerAlertRef = useRef<any>(null);
  const methods = useFormContext();

  const { errors } = methods.formState;

  const email = methods.watch('email');
  const isAgree = methods.watch('isAgree');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    'An error occurred while subscribing. Please try again later',
  );

  const isAllowSubmit: boolean = useMemo(() => {
    if (!isAgree || !email) {
      return false;
    }
    return true;
  }, [isAgree, email]);

  const handleShowErrorAlert = () => {
    setIsShowAlert(true);

    if (timerAlertRef.current) {
      clearTimeout(timerAlertRef.current);
    }

    timerAlertRef.current = setTimeout(() => {
      setIsShowAlert(false);
    }, 5000);
  };

  const handleSubmitForm = async (data: any) => {
    const topicIds: ITopics[] = methods.getValues('topics');
    const languages: string[] = methods.getValues('languages');
    const days: string[] = methods.getValues('days');
    const time: string = methods.getValues('time');

    let dataToSubmit: IDataSubscribeToTopics = {
      email: data.email,
      topicIds: topicIds && topicIds.length > 0 ? [...topicIds.map((topic) => topic.id!)] : [],
    };

    if (data.instant) {
      dataToSubmit.notifications = {
        scheduling: {
          instant: true,
        },
      };
    } else {
      dataToSubmit.notifications = {
        scheduling: {
          instant: false,
          intervals: [
            {
              days: days,
              time: time ? formatDate.format(time, DATE_FORMAT_HH_MM) : '',
            },
          ],
        },
      };
    }

    if (languages && languages.length > 0) {
      dataToSubmit.notifications = {
        ...dataToSubmit.notifications,
        languages: languages,
      };
    }

    try {
      setIsSubmitting(true);
      await subscribeForUpdateService.subscribeToTopics(dataToSubmit);
      onNext?.();
    } catch (error: any) {
      console.log({ error });
      if (error?.response.data.message) {
        setErrorMessage(`An error occurred while subscribing. ${error?.response.data.message}`);
      }
      handleShowErrorAlert();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGetTermsAndConditionsLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/terms/';
      case 'nl':
        return 'https://www.belga.press/nl/terms/';
      case 'fr':
        return 'https://www.belga.press/fr/terms/';
      default:
        return 'https://www.belga.press/terms/';
    }
  };

  const handleGetPrivacyPolicyLink = () => {
    switch (locale.toLocaleLowerCase()) {
      case 'en':
        return 'https://www.belga.press/privacy/';
      case 'nl':
        return 'https://www.belga.press/nl/privacy/';
      case 'fr':
        return 'https://www.belga.press/fr/privacy/';
      default:
        return 'https://www.belga.press/privacy/';
    }
  };

  return (
    <Box>
      <TypographyCustom>
        <WrapperLabel>
          <TypographyCustom>
            <FormattedMessage id={'email'} />
          </TypographyCustom>
          <TypographyRequireCustom>*</TypographyRequireCustom>
        </WrapperLabel>
        <Controller
          name="email"
          control={methods.control}
          rules={{
            required: true,
            pattern: {
              value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
              message: 'Invalid email address',
            },
          }}
          render={({ field: { ref, value, onChange } }) => (
            <InputSecondary
              ref={ref}
              placeholderMessageId={'email'}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors?.email && (
          <Typography
            sx={{
              fontSize: '10px',
              lineHeight: '16px',
              color: theme.palette.error.main,
              fontWeight: 600,
              marginTop: theme.spacing(0.5),
              marginLeft: theme.spacing(0.2),
            }}
          >
            {errors?.email?.message as string}
          </Typography>
        )}
      </TypographyCustom>
      <>
        <WrapperCheckbox>
          <CheckboxBox>
            <Checkbox
              checked={isAgree}
              onChange={(event) => {
                methods.setValue('isAgree', event.target.checked);
              }}
            />
          </CheckboxBox>
          <Box>
            <TypographyCheckboxTitle>
              <FormattedMessage id={'subscribe.form.modal.3.condition'} />
              <Link
                href={handleGetTermsAndConditionsLink()}
                target="_blank"
                style={{
                  transition: '.3s ease-in-out',
                  fontSize: '14px',
                  lineHeight: '24px',
                  display: 'inline',
                }}
              >
                <TypographyCheckboxTitleUnderLine
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    display: 'inline',
                    ':hover': {
                      color: theme.palette.global01[400],
                    },
                  }}
                >
                  <FormattedMessage id={'subscribe.form.modal.3.term.and.condition'} />
                </TypographyCheckboxTitleUnderLine>
              </Link>
            </TypographyCheckboxTitle>
            <Typography
              sx={{
                marginTop: theme.spacing(0.5),
                fontSize: '12px',
                lineHeight: '24px',
              }}
            >
              <TypographyCheckboxDescription
                sx={{ display: 'inline', fontSize: '12px', lineHeight: '24px' }}
              >
                <FormattedMessage id={'subscribe.form.modal.3.condition.description.1'} />
                <Link
                  href={handleGetTermsAndConditionsLink()}
                  target="_blank"
                  style={{
                    transition: '.3s ease-in-out',
                    fontSize: '12px',
                    lineHeight: '24px',
                    display: 'inline',
                  }}
                >
                  <TypographyCheckboxTitleUnderLine
                    sx={{
                      fontSize: '12px',
                      lineHeight: '24px',
                      display: 'inline',
                      ':hover': {
                        color: theme.palette.global01[400],
                      },
                    }}
                  >
                    <FormattedMessage id={'subscribe.form.modal.3.term.and.condition'} />
                  </TypographyCheckboxTitleUnderLine>
                </Link>
              </TypographyCheckboxDescription>
            </Typography>
            <TypographyCheckboxDescription sx={{ marginTop: theme.spacing(0.5) }}>
              <FormattedMessage id={'subscribe.form.modal.3.condition.description.2'} />
              <Link
                href={handleGetPrivacyPolicyLink()}
                target="_blank"
                style={{
                  transition: '.3s ease-in-out',
                  fontSize: '12px',
                  lineHeight: '24px',
                  display: 'inline',
                }}
              >
                <TypographyCheckboxTitleUnderLine
                  sx={{
                    fontSize: '12px',
                    lineHeight: '24px',
                    display: 'inline',
                    ':hover': {
                      color: theme.palette.global01[400],
                    },
                  }}
                >
                  <FormattedMessage id={'footer.privacy_policy'} />
                </TypographyCheckboxTitleUnderLine>
              </Link>
            </TypographyCheckboxDescription>
          </Box>
        </WrapperCheckbox>
        <AlertCustom showAlert={isShowAlert} message={errorMessage} />
        <FlexWrapper
          sx={{
            marginTop: theme.spacing(3),
            justifyContent: 'space-between',
            gap: theme.spacing(3),
          }}
        >
          <ButtonSecondary
            spacingBetweenIcon={0.5}
            prefixIcon={<IcoArrowLeft fill={theme.palette.global01[400]} />}
            buttonText={'button.go.back'}
            onClick={onBack}
            sx={{ flex: isSmall ? 1 : '' }}
          />
          <LoadingButtonCustom
            suffixIcon={
              <IcoArrowRight
                fill={email && isAgree ? theme.palette.neutral01[700] : theme.palette.text.disabled}
              />
            }
            loadingPosition={'end'}
            loading={isSubmitting}
            onClick={() => {
              methods.handleSubmit((data) => handleSubmitForm(data))();
            }}
            disabled={!isAllowSubmit}
            buttonText={'button.create.and.subscribe'}
            sx={{
              color:
                !isAllowSubmit && !isSubmitting
                  ? theme.palette.text.disabled
                  : '#ffffff !important',
              border: 'none !important',
              background: !isAllowSubmit ? 'rgba(0, 0, 0, 0.12)' : theme.palette.global01[400],
              ':hover': {
                background: !isAllowSubmit
                  ? 'rgba(0, 0, 0, 0.12)'
                  : isSubmitting
                  ? theme.palette.global01[400]
                  : theme.palette.global01[300],
              },
              flex: isSmall ? 1 : '',
            }}
          />
        </FlexWrapper>
      </>
    </Box>
  );
};

export default StepThreeSection;
