import React from 'react';
import { TopicSelectedItemCustom } from './TopicSelectedItem.style';
import { IcoCloseSmall } from 'components/@icons';
import { theme } from 'theme';
import { Box } from '@mui/material';
import { ITopics } from 'types/types';
import { useLocale } from 'hooks/useLocale';

interface Props {
  topic: ITopics;
  onRemoveTopicSelected: (topic: ITopics) => void;
}

const TopicSelectedItem = ({ topic, onRemoveTopicSelected }: Props) => {
  const { locale } = useLocale();

  return (
    <TopicSelectedItemCustom onClick={() => onRemoveTopicSelected(topic)}>
      <Box>{topic.content?.[locale]?.title}</Box>{' '}
      <IcoCloseSmall fill={theme.palette.neutral01[400]} />
    </TopicSelectedItemCustom>
  );
};

export default TopicSelectedItem;
