import { Box, Typography, styled } from '@mui/material';

export const TopicItemCustom = styled(Box)<{ $active?: boolean }>(({ theme, $active }) => ({
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  overflow: 'hidden',
  backgroundColor: $active ? theme.palette.global01[400] : theme.palette.global01[700],
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  paddingRight: theme.spacing(1),
  cursor: 'pointer',
  transition: '0.3s',
}));

export const TopicItemTitle = styled(Box)<{ $active: boolean }>(({ theme, $active }) => ({
  ...theme.typography.webParagraph03,
  color: $active ? theme.palette.neutral01[700] : theme.palette.neutral01[100],
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
  userSelect: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: 11,
  },
}));

export const TypographyCustom = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading06,
  color: theme.palette.neutral01[100],
}));
